.MuiTablePagination-input {
  display: none!important;
}
.orderBreakdown {
padding-left: 20px;
}
.orderBreakdown li {
  margin-right:  20px;
}
.orderItemList {
  padding-top: 20px;
  display: flex;
}
.orderItems {
  margin-right: 100px;
}
.collapsible {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.warrantyButton{
  height: 35px;
}
.labelText{
  font-size:  0.875rem!important;
  color:black
}
.commentTextField{
  margin-top: 20px;
  width: 100%;
}